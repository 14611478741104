<template>
  <div
    id="settings-proposal-invoice"
    class="lg:mt-5 lg:px-4 max-w-2xl mx-auto side-nav short"
  >
    <vue-headful
      title="Settings - Proposal & Invoice | Octoa"
    />
    <div class="flex flex-wrap mb-5">
      <div class="w-full lg:w-1/4">
        <setting-menu/>
      </div>
      <div class="w-full lg:w-3/4">
        <div
          class="bg-white h-full mx-4 my-4 px-4 py-4 lg:mx-0 lg:my-0 lg:px-8 lg:py-8 border-grey-lighter border rounded">
          <div
            class="w-full px-2 text-center"
          >
            <img
              src="@/assets/img/icons/loader.svg"
              width="50"
              class="mt-32 text-center"
            >
          </div>
          <h1 class="text-2xl font-bold text-center mb-4">Connecting your Mollie account... Please wait, do not refresh.</h1>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import auth from "@/auth";

export default {
  name: 'SettingsMollie',
  data() {
    return {
      user: auth.user(),
    }
  },
  async mounted() {
    if (this.$route.query.code) {
      await this.$api.get('mollie').saveAuth(this.user.company.id, this.$route.query.code)
    }

    this.$router.push({ path: '/settings/invoicing' })
  },
};
</script>
